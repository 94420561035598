@each $border-size in $border-sizes {
    .b-#{$border-size} {
        border: #{$border-size + 'px'} solid;
        @each $suffix, $direction in $directions {
            &\:#{$suffix} {
                border-#{$direction}: #{$border-size + 'px'} solid;
            }
        }
        &\:v {
            border-top: #{$border-size + 'px'} solid;
            border-bottom: #{$border-size + 'px'} solid;
        }
        &\:h {
            border-left: #{$border-size + 'px'} solid;
            border-right: #{$border-size + 'px'} solid;
        }
    }
}
.br-round {
    border-radius: 50%;
}
@each $border-radius in $border-radii {
    .br-#{$border-radius} {
        border-radius: #{$border-radius + $border-radius-unit};
        &\:tl {
            border-top-left-radius: #{$border-radius + $border-radius-unit};
        }
        &\:tr {
            border-top-right-radius: #{$border-radius + $border-radius-unit};
        }
        &\:br {
            border-bottom-right-radius: #{$border-radius + $border-radius-unit};
        }
        &\:bl {
            border-bottom-left-radius: #{$border-radius + $border-radius-unit};
        }
        &\:t {
            border-top-left-radius: #{$border-radius + $border-radius-unit};
            border-top-right-radius: #{$border-radius + $border-radius-unit};
        }
        &\:b {
            border-bottom-left-radius: #{$border-radius + $border-radius-unit};
            border-bottom-right-radius: #{$border-radius + $border-radius-unit};
        }
        &\:l {
            border-top-left-radius: #{$border-radius + $border-radius-unit};
            border-bottom-left-radius: #{$border-radius + $border-radius-unit};
        }
        &\:r {
            border-top-right-radius: #{$border-radius + $border-radius-unit};
            border-bottom-right-radius: #{$border-radius + $border-radius-unit};
        }
    }
}
@each $prefix, $breakpoint in $breakpoints {
    @media (min-width: $breakpoint) {
        @each $border-size in $border-sizes {
            .#{$prefix}\:b-#{$border-size} {
                border: #{$border-size + 'px'} solid;
                @each $suffix, $direction in $directions {
                    &\:#{$suffix} {
                        border-#{$direction}: #{$border-size + 'px'} solid;
                    }
                }
                &\:v {
                    border-top: #{$border-size + 'px'} solid;
                    border-bottom: #{$border-size + 'px'} solid;
                }
                &\:h {
                    border-left: #{$border-size + 'px'} solid;
                    border-right: #{$border-size + 'px'} solid;
                }
            }
        }
    }
}
// Do we need media prefixes for border radius?
