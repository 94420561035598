input[type='radio'],
input[type='checkbox'] {
    @include visibly-hidden;

    &:focus-visible + label {
        outline: auto;
    }
}
input[type='radio'] + label,
input[type='checkbox'] + label {
    cursor: pointer;
    margin: 0 0 1em 0;
    font-size: 1em;
    line-height: 1.4em;
    padding-left: 26px;
    display: flex;
    font-weight: normal;
    padding-left: 0;
    color: $grey-1;
    &:before {
        content: '';
        background-color: #fff;
        background-repeat: no-repeat;
        border: 1.5px solid $grey-2;
        height: 28px;
        width: 28px;
        margin: -2px 8px 0 0;
        flex-shrink: 0;
    }
    &.toggle {
        position: relative;
        &:after {
            content: '';
            position: absolute;
            flex-shrink: 0;
            width: 22px;
            height: 22px;
            border-radius: 50%;
            left: 0;
            transition: transform 75ms ease-out;
            box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.2);
            background-color: $white;
            border: 1px solid $grey-4;
        }
        &:before {
            width: 40px;
            height: 16px;
            background-color: $grey-3;
            border-radius: 8px;
            border: 0 none;
            margin-top: 4px;
            transition: background-color 100ms ease-out;
        }
    }
    &:focus-visible {
        color: red;
    }
}
input[type='checkbox'] {
    + label:before {
        border-radius: 4px;
        background-image: url('/assets/icons/input-valid.svg');
        background-position: center;
        background-size: 0 auto;
    }
    &:disabled + label:before {
        background-color: $grey-3;
        border-color: $text-muted;
        filter: grayscale(100%);
    }
    &:checked + label:before {
        background-size: 24px auto;
    }
    &:checked + label.toggle {
        &:before {
            background-color: $blue-2;
            background-image: none;
        }
        &:after {
            transform: translateX(18px);
        }
    }
}
input[type='radio'] {
    + label:before {
        border-radius: 50%;
    }
    &:checked + label:before {
        background-image: radial-gradient($blue-2 7px, transparent 7.75px);
    }
}

input[type='radio'] + label.a-b-switch {
    border: 1px solid #b0b0b0;
    display: inline-block;
    width: auto;
    padding: 8px 16px;
    margin: 0;
    background-image: linear-gradient(white, $grey-5);

    &:before {
        content: none;
        display: none;
    }
    &:first-of-type {
        border-radius: 4px 0 0 4px;
    }
    &:not(:last-of-type) {
        border-right: 0 none;
    }
    &:last-of-type {
        border-radius: 0 4px 4px 0;
    }
}
input[type='radio']:checked + label.a-b-switch {
    color: white;
    background-color: $blue-1;
    background-image: linear-gradient($blue-2, $blue-1);
}
.a-b-switch-container {
    border-radius: 4px;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.1);
    display: inline-block;
}
