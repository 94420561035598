/* You can add global styles to this file, and also import other style files */

// reset and normalize browser styles:
@import 'scss/reset';
// set variables:
@import 'scss/variables';
// import & define fonts:
@import 'scss/fonts';
// body and content container styles:
@import 'scss/containers';
// grid layout:
@import 'scss/grid';
// headlines, paragraphs, lists etc:
@import 'scss/text';
// input elements (except radio buttons and checkboxes):
@import 'scss/inputs';
// lists and list elements
@import 'scss/lists';
// buttons:
@import 'scss/buttons';
// radio buttons and checkboxes:
@import 'scss/radio';
// purely visual elements like hr:
@import 'scss/visuals';
// margins:
@import 'scss/margins';
// paddings:
@import 'scss/paddings';
// positions:
@import 'scss/positions';
// floating:
@import 'scss/floating';
// display:
@import 'scss/display';
// images:
@import 'scss/images';
// borders:
@import 'scss/borders';
// colors:
@import 'scss/colors';
// tooltips:
@import 'scss/tooltips';
// modals:
@import 'scss/modals';
// tables:
@import 'scss/tables';
// icons:
@import 'scss/ui-icons';
