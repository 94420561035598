// images
.img\: {
    &fluid {
        max-width: 100%;
        height: auto;
        display: block;
    }
    &center {
        display: block;
        margin-left: auto;
        margin-right: auto;
    }
}
