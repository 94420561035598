.backdrop {
    background-color: rgba(0, 0, 0, 0.7);
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    z-index: 100000;
}
.modal {
    background-color: $white;
    border-radius: 8px;
    box-shadow:
        0 2px 4px rgba(0, 0, 0, 0.2),
        0 6px 14px rgba(0, 0, 0, 0.1);
    position: absolute;
    left: 24px;
    width: calc(100% - 48px);
    top: 50%;
    padding: 24px;
    transform: translate(0, -50%);

    @media (min-width: $md) {
        width: 800px;
        left: calc(50% - 400px);
    }

    &.-with-buttons {
        padding-bottom: 100px;

        .modal-content {
            max-height: calc(100vh - 196px);
        }
    }

    .modal-buttons {
        position: absolute;
        bottom: 24px;
        left: 24px;
        width: calc(100% - 48px);
    }

    .modal-content {
        overflow: auto;
        max-height: calc(100vh - 96px);
    }
}

ctrl-modal {
    max-width: 640px;
    border-radius: 8px;
    border: 1.5px solid;
    border-top: 0;
    border-color: transparent #00a4d9 #0087b5 #0062a8;
    --ctrl-modal-padding: 1.5rem;
    box-sizing: border-box;
    width: calc(100% - 2rem);
    max-height: calc(100% - 2rem);
    display: flex;
    flex-direction: column;

    &.narrow {
        max-width: 480px;
    }
}

.ctrl-modal-backdrop {
    z-index: 100000;
}

.ctrl-modal-header {
    background-image: $gradient-slanted-rev;
    padding: 1.5rem;
    margin: -1.5rem -1.5rem 1.5rem;
    border-radius: 6px 6px 0 0;

    &:has(> .no-background) {
        background: none;
        margin-bottom: 0;
        padding-bottom: 0;
    }
}

.ctrl-modal-content {
    overflow-y: auto;
    overflow-x: hidden;
}
