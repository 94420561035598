// display:
.hide {
    display: none !important;
}
.show {
    display: initial !important;
}
.block {
    display: block !important;
}
.inline-block {
    display: inline-block !important;
}
.inline {
    display: inline !important;
}
.tcell {
    display: table-cell !important;
}
.litem {
    display: list-item !important;
}
@each $prefix, $breakpoint in $breakpoints {
    @media (min-width: $breakpoint) {
        .#{$prefix}\:hide {
            display: none !important;
        }
        .#{$prefix}\:show {
            display: initial !important;
        }
        .#{$prefix}\:block {
            display: block !important;
        }
        .#{$prefix}\:inline-block {
            display: inline-block !important;
        }
        .#{$prefix}\:inline {
            display: inline !important;
        }
        .#{$prefix}\:tcell {
            display: table-cell !important;
        }
        .#{$prefix}\:litem {
            display: list-item !important;
        }
    }
}
