// put all variables here

// brand colors:

$blue-1: #0062a8;
$blue-2: #0087b5;
$blue-3: #00a4d9;

$grey-1: #606060;
$grey-2: #afafaf;
$grey-3: #d1d1d1;
$grey-4: #e9e9e9;
$grey-5: #f4f4f4;
$grey-6: #fcfcfc;
$white: #ffffff;
$black: #000000;

$text-color: $grey-1; // use for body copy, lists and so on
$headline-color: $grey-1; // use for headlines
$text-muted: $grey-2; // use for text that is less important
$bg-color: $grey-5;
$gradient-slanted: linear-gradient(135deg, $blue-3 35%, $blue-1);
$gradient-slanted-rev: linear-gradient(315deg, $blue-3 35%, $blue-1);
$gradient-horizontal: linear-gradient(to right, $blue-3, $blue-2, $blue-1);
$debug-gradient: linear-gradient(to right, red 33%, green 33%, green 66%, yellow 66%);
$debug-gradient-slanted: linear-gradient(135deg, red 33%, green 33%, green 66%, yellow 66%);
$filter-to-white: brightness(0) saturate(100%) invert(99%) sepia(0%) saturate(0%) hue-rotate(55deg) brightness(113%)
    contrast(100%);

$input-border: #9c9c9c; // use for input borders

// ui colors:

$red: #a80000; // use for alerts, error notifications
$green: darken(#00ae10, 10%); // use for positive notifications
$orange: #f7a600; // use for warnings

// define color names and the corresponding colors for color helper classes:
$colors: (
    'blue-1': $blue-1,
    'blue-2': $blue-2,
    'blue-3': $blue-3,
    'grey-1': $grey-1,
    'grey-2': $grey-2,
    'grey-3': $grey-3,
    'grey-4': $grey-4,
    'grey-5': $grey-5,
    'grey-6': $grey-6,
    'white': $white,
    'black': $black,
    'red': $red,
    'green': $green,
    'orange': $orange,
    'muted': $text-muted,
    'text': $text-color,
    'headline': $headline-color,
);

// fonts:

$body-font: 'Roboto', 'Arial', sans-serif;
$headline-font: $body-font;
$font-size-base: 1em;
$font-size-base-md: 1.125em;
$font-sizes: (
    'xs': 0.8em,
    's': 0.9em,
    'm': 1em,
    'l': 1.2em,
    'xl': 1.4em,
);
$font-weights: (
    'light': 300,
    'regular': 400,
    'semibold': 500,
    'bold': 700,
);

// containers:

$content-width: 1400px;
$medium-content-width: 920px;
$content-padding-xs: 8px;
$content-padding-sm: 24px;
$content-padding-md: 32px;
$content-padding-lg: 48px;
$content-padding-xl: 72px;

// breakpoints:
$xs: 520px;
$sm: 640px;
$md: 920px;
$lg: 1200px;
$xl: 1600px;

$breakpoint-xs: $xs;
$breakpoint-sm: $sm;
$breakpoint-md: $md;
$breakpoint-lg: $lg;
$breakpoint-xl: $xl;

$breakpoints: (
    'xs': $xs,
    'sm': $sm,
    'md': $md,
    'lg': $lg,
    'xl': $xl,
);

// gutter width:

$gutter-sm: calc($content-padding-sm / 2);
$gutter-md: calc($content-padding-md / 2);
$gutter-lg: calc($content-padding-lg / 2);
$gutter-xl: calc($content-padding-xl / 2);
$gutter: 32px;
$gutters: (
    'sm': $gutter-sm,
    'md': $gutter-md,
    'lg': $gutter-lg,
    'xl': $gutter-xl,
);

// paddings:

$paddings: 0, 6, 8, 12, 16, 24, 32, 36, 48, 64, 72, 96;
$padding-unit: 'px';

// margins:

$margins: 0, 6, 8, 12, 16, 24, 32, 36, 48, 64, 72, 96;
$margin-unit: 'px';

// directions:

$directions: (
    't': 'top',
    'b': 'bottom',
    'l': 'left',
    'r': 'right',
);

// grid

$columns: 12;

// borders:

$border-sizes: 0, 1, 2, 4;
$border-radii: 0, 2, 3, 4;
$border-radius-unit: 'px';
