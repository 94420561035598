// floating
.float\: {
    &l,
    &left {
        float: left;
    }
    &r,
    &right {
        float: right;
    }
    &none {
        float: none;
    }
}
@each $prefix, $breakpoint in $breakpoints {
    @media (min-width: $breakpoint) {
        .#{$prefix}\:float\: {
            &l,
            &left {
                float: left;
            }
            &r,
            &right {
                float: right;
            }
            &none {
                float: none;
            }
        }
    }
}
