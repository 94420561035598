table.-collapsible {
    @media (max-width: #{$breakpoint-md - 1 }) {
        display: block;
        thead {
            display: block;
            tr {
                display: flex;
                justify-content: space-between;
                th {
                    display: block;
                    width: 100%;
                }
            }
        }
        tbody {
            display: block;
            tr {
                display: flex;
                justify-content: center;
                td {
                    display: block;
                }
            }
        }
    }
}