

.has-tooltip {
    position: relative;

    @media (hover) {
        &:hover .tooltip {
            opacity: 1;
        }
    }
    &:not(button):focus .tooltip,
    .tooltip.force-show {
        opacity: 1;
    }
}

.form-control {
    position: relative;
    input:focus ~ .tooltip,
    textarea:focus ~ .tooltip {
        opacity: 1;
    }
}

.tooltip {
    opacity: 0;
    position: absolute;
    border: 1px solid $grey-1;
    border-radius: 4px;
    color: black;
    background-color: $grey-3;
    font-size: 12px;
    max-width: 200px;
    padding: 4px 8px;
    box-shadow: 0 0 4px rgba(0, 0, 0, 0.1), 0 2px 8px rgba(0, 0, 0, 0.05);
    pointer-events: none;
    text-align: left;
    width: max-content;
    font-weight: 400;
    white-space: normal;
    word-wrap: normal;
    z-index: 500;

    &:before,
    &:after,
    &.-top:before,
    &.-top:after {
        content: '';
        display: block;
        position: absolute;
        top: 100%;
        left: calc(50% - 6px);
        border-width: 10px 6px 0;
        border-color: $grey-1 transparent transparent;
        border-style: solid;
    }

    &:after,
    &.-top:after {
        border-color: $grey-3 transparent transparent;
        margin-top: -1.5px;
    }

    &.-top {
        bottom: calc(100% + 10px);
        left: 50%;
        transform: translateX(-50%);
    }

    &.-top-left {
        bottom: calc(100% + 10px);
        left: 0.6em;
        transform: none;

        &:before,
        &:after {
            left: 8px;
        }
    }

    &.-top-right {
        bottom: calc(100% + 10px);
        right: 0.6em;
        transform: none;

        &:before,
        &:after {
            right: 8px;
            left: auto;
        }
    }

    &.-left {
        right: calc(100% + 10px);
        top: 50%;
        transform: translateY(-50%);

        &:before,
        &:after {
            top: calc(50% - 6px);
            left: 100%;
            border-width: 6px 0 6px 10px;
            border-color: transparent transparent transparent $grey-1;
        }
        &:after {
            border-color: transparent transparent transparent $grey-3;
            margin-top: 0;
            margin-left: -1.5px;
        }
    }
    &.-bottom {
        top: calc(100% + 10px);
        left: 50%;
        transform: translateX(-50%);

        &:before,
        &:after {
            top: auto;
            bottom: 100%;
            border-width: 0 6px 10px;
            border-color: transparent transparent $grey-1;
            border-style: solid;
        }
        &:after {
            border-color: transparent transparent $grey-3;
            margin-top: 0;
            margin-bottom: -1.5px;
        }
    }
}

// adds padding to the container to make space for the info tooltip
.with-info-tooltip {
    padding-right: 32px;
}
