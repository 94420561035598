.form-control {
    position: relative;
    margin-bottom: 1em;
    @media screen and (min-width: $breakpoint-md) {
        margin-bottom: 1.33em;
    }

    input,
    select,
    textarea {
        margin-bottom: 0;
    }

    &:has(.ng-touched.ng-invalid) {
        label {
            color: $red;
        }
    }

    .form-error-note {
        font-size: 0.8em;
        margin-top: 4px;
        color: $red;
    }
}

label,
.label {
    font-size: 0.8em;
    color: $grey-2;
    display: block;
    font-weight: 600;
    margin-bottom: 2px;

    &[appReactiveLabel] {
        transition:
            color 100ms,
            transform 100ms;

        &.-reactive {
            transform: translateY(50%) scaleY(50%);
            color: transparent;
        }
        &.-has-value {
            color: $grey-2;
            transform: none;
            &.-is-disabled {
                color: $grey-4;
            }
        }
        &.-is-disabled:not(.-reactive) {
            color: $grey-4;
        }
        &.ng-touched.ng-invalid {
            color: $red;
            transform: none;
        }
    }
}

input,
select,
textarea {
    border-style: solid;
    border-color: inherit;
    outline: none;

    &.ng-invalid:not(.ng-untouched):not(:disabled) {
        border-color: $red;
    }

    &::-webkit-input-placeholder {
        color: $grey-2;
        opacity: 1;
    }
    &:-moz-placeholder {
        color: $grey-2;
        opacity: 1;
    }
    &::-moz-placeholder {
        color: $grey-2;
        opacity: 1;
    }
    &:-ms-input-placeholder {
        color: $grey-2;
        opacity: 1;
    }
    &::placeholder {
        color: $grey-2;
        opacity: 1;
    }
    &:disabled {
        &::-webkit-input-placeholder {
            color: $grey-2;
            opacity: 0.5;
        }
        &:-moz-placeholder {
            color: $grey-2;
            opacity: 0.5;
        }
        &::-moz-placeholder {
            color: $grey-2;
            opacity: 0.5;
        }
        &:-ms-input-placeholder {
            color: $grey-2;
            opacity: 0.5;
        }
        &::placeholder {
            color: $grey-4;
            opacity: 0.5;
        }
    }
}

input,
select,
textarea {
    display: block;
    width: 100%;
    border-width: 0 0 1.5px;
    border-color: $grey-2;
    font-size: 1.2em;
    padding: 8px;
    background-color: transparent;
    color: $grey-1;
    border-radius: 0;
    font-family: $body-font;
    margin-bottom: 1em;
    font-weight: 400;
    text-overflow: ellipsis;

    &:disabled {
        background-image: none;
        color: $grey-4;
        border-color: $grey-4;
        cursor: not-allowed;
        & + label {
            color: $grey-4;
        }
    }
    &:focus {
        border-color: $blue-2;
        border-width: 0 0 2px;
    }
}

input[type='number'],
input[type='text'],
input[type='password'],
input[type='email'],
input[type='tel'] {
    &[required].ng-valid,
    &.ng-valid.ng-dirty {
        background-image: url('/assets/icons/icon_check.svg');
        background-repeat: no-repeat;
        background-position: right center;
        background-size: 23px 23px;
        padding-right: 32px;
    }
}

input[type='number'] {
    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }
    -moz-appearance: textfield;
}

select {
    -webkit-appearance: none;
    -moz-appearance: none;
    background-image: url('/assets/icons/icon_chevron_down.svg');
    background-repeat: no-repeat;
    background-position: right 8px center;
    padding-right: 32px;

    &.ng-untouched.ng-invalid {
        color: $grey-2;
    }
    option {
        color: $grey-1;
        &:disabled,
        &:disabled:hover {
            color: $grey-2;
        }
    }
}

textarea {
    background-color: $grey-6;
}

// Steinbruch:a

.grouped-form {
    background-color: $grey-5;
    border: 2px dotted $grey-1;
    input,
    select {
        background-color: $white;
    }
    label {
        background-color: $white;
        transform: none !important;
        margin-bottom: 0;
        padding-top: 4px;
        padding-left: 4px;

        &.-display {
            background-color: transparent;
        }
    }
}

.sub-input {
    padding-left: 4em;
    &:before {
        content: '';
        left: 1em;
        top: 0.25em;
        width: 2em;
        height: 2em;
        position: absolute;
        border-left: 1.5px solid $grey-2;
        border-bottom: 1.5px solid $grey-2;
    }

    + .form-error-note {
        padding-left: 5em;
    }
}
