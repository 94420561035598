@use 'sass:math';
@import './variables';

@mixin grid-element {
    margin-left: math.div($gutter, 2);
    margin-right: math.div($gutter, 2);
    box-sizing: border-box;
}

.grid-container {
    flex-wrap: wrap;
    margin-left: math.div($gutter, 2) * -1;
    margin-right: math.div($gutter, 2) * -1;
    display: flex;
    flex-direction: row;

    &.-equal-h {
        align-items: stretch;
    }
    &.-spread-v {
        align-content: space-between;
        height: 100%;
    }
    &.-center-v {
        align-items: center;
    }
    &.-bottom-v {
        align-items: flex-end;
    }
    &.-no-wrap {
        flex-wrap: nowrap;
    }
    &.-center-content {
        justify-content: center;
    }
    &.-spread-content {
        justify-content: space-between;
    }
    &.-end {
        justify-content: end;
    }
    &.-column {
        flex-direction: column;
    }
}

@each $gutter-prefix, $gutterValue in $gutters {
    .vertical-gutter-#{$gutter-prefix} {
        margin-bottom: -$gutterValue;

        & > * {
            margin-bottom: $gutterValue;
        }
    }

    @each $prefix, $breakpoint in $breakpoints {
        @media (min-width: $breakpoint) {
            .#{$prefix}\:vertical-gutter-#{$gutter-prefix} {
                margin-bottom: -$gutterValue;

                & > * {
                    margin-bottom: $gutterValue;
                }
            }
        }
    }
}

.g-auto {
    flex-basis: content;
    @include grid-element;
}

@for $i from 1 through $columns {
    .g-#{$i} {
        flex-basis: calc(#{math.div($i, $columns) * 100%} - #{$gutter});
        margin-left: math.div($gutter, 2);
        margin-right: math.div($gutter, 2);
    }
}
@each $prefix, $breakpoint in $breakpoints {
    @media (min-width: $breakpoint) {
        .#{$prefix}\:g-auto {
            flex-basis: content;
            @include grid-element;
        }
        @for $i from 1 through $columns {
            .#{$prefix}\:g-#{$i} {
                @include grid-element;
                flex-basis: calc(#{math.div($i, $columns) * 100%} - #{$gutter});
            }
        }
    }
}
.g-offset-0 {
    margin-left: math.div($gutter, 2);
}
@for $i from 1 through $columns {
    .g-offset-#{$i} {
        margin-left: calc(#{math.div($i, $columns) * 100%} + #{math.div($gutter, 2)});
    }
}
@each $prefix, $breakpoint in $breakpoints {
    @media (min-width: $breakpoint) {
        .#{$prefix}\:g-offset-0 {
            margin-left: math.div($gutter, 2);
        }
        @for $i from 1 through $columns {
            .#{$prefix}\:g-offset-#{$i} {
                margin-left: calc(#{math.div($i, $columns) * 100%} + #{math.div($gutter, 2)});
            }
        }
    }
}
@for $i from 0 through 5 {
    .g-order-#{$i} {
        order: $i;
    }
}
@each $prefix, $breakpoint in $breakpoints {
    @media (min-width: $breakpoint) {
        @for $i from 0 through 5 {
            .#{$prefix}\:g-order-#{$i} {
                order: $i;
            }
        }
    }
}

.clearfix:after {
    content: '';
    display: table;
    clear: both;
}
.flex-fix {
    min-width: 0;
}
.flex-grow {
    flex-grow: 1;
}
