@each $padding in $paddings {
    .p-#{$padding} {
        padding: #{$padding + $padding-unit};
        @each $suffix, $direction in $directions {
            &\:#{$suffix} {
                padding-#{$direction}: #{$padding + $padding-unit};
            }
        }
        &\:v {
            padding-top: #{$padding + $padding-unit};
            padding-bottom: #{$padding + $padding-unit};
        }
        &\:h {
            padding-left: #{$padding + $padding-unit};
            padding-right: #{$padding + $padding-unit};
        }
    }
}
@each $prefix, $breakpoint in $breakpoints {
    @media (min-width: $breakpoint) {
        @each $padding in $paddings {
            .#{$prefix}\:p-#{$padding} {
                padding: #{$padding + $padding-unit};
                @each $suffix, $direction in $directions {
                    &\:#{$suffix} {
                        padding-#{$direction}: #{$padding + $padding-unit};
                    }
                }
                &\:v {
                    padding-top: #{$padding + $padding-unit};
                    padding-bottom: #{$padding + $padding-unit};
                }
                &\:h {
                    padding-left: #{$padding + $padding-unit};
                    padding-right: #{$padding + $padding-unit};
                }
            }
        }
    }
}
