button {
    background: none;
    border: none;
    padding: 0;
}

button[type='submit'],
button[type='reset'],
input[type='file'] + label,
.button {
    font-family: $body-font;
    font-weight: 600;
    color: white;
    border: 0 none;
    background-color: $orange;
    padding: 12px 32px;
    line-height: 1em;
    display: inline-block;
    text-align: center;
    text-decoration: none;
    border-radius: 4px;
    cursor: pointer;
    background-repeat: no-repeat;
    white-space: nowrap;
    width: 100%;

    &:hover {
        text-decoration: none;
    }

    &.-full-width {
        width: 100%;
    }

    &.-width-auto {
        width: auto;
    }

    &.-dark {
        background-color: $grey-1;
        background-image: linear-gradient(lighten($grey-1, 7%), $grey-1);
    }

    &.-blue {
        display: flex;
        height: 42px;
        padding: 8px 16px;
        justify-content: center;
        align-items: center;
        gap: 8px;
        align-self: stretch;
        border-radius: 8px;
        background: var(--linear-blue-light-to-dark, linear-gradient(122deg, $blue-3 1.45%, $blue-1 97.74%));

        &.-negative {
            color: $blue-2;
            border: 1px solid transparent;
            background:
                linear-gradient($white 0 0) padding-box,
                var(--linear-blue-light-to-dark, linear-gradient(122deg, $blue-3 1.45%, $blue-1 97.74%)) border-box;
        }
    }

    &.-sqr {
        width: 42px;
        padding: 8px;
    }

    &.-ghost {
        color: $headline-color;
        border: 1px solid $headline-color;
        display: inline-block;
        padding: 8px 12px;
        background-image: none;
        background-color: transparent;
        border-radius: 3px;
        box-shadow: 0 0 transparent;
        width: auto;
        line-height: 1.4;
        font-weight: normal;

        &:hover {
            background-color: $grey-5;
            color: $blue-2;
        }

        &.-full-width {
            width: 100%;
        }
    }

    &.-delete {
        background-image: url('/assets/icons/icon_delete.svg');
        background-repeat: no-repeat;
        background-position: center left 12px;
        padding-left: 48px;
    }

    &.-add {
        background-image: url('/assets/icons/icon_add.svg');
        background-repeat: no-repeat;
        background-position: center left 12px;
        padding-left: 48px;
    }

    &:disabled,
    &.disabled {
        background-color: $grey-3;
        box-shadow: 0 0 0 transparent;
        background-image: none;

        cursor: not-allowed;

        &:hover + .invalid-tooltip {
            opacity: 1;
            transform: translate(-50%, 0);
        }
    }

    + .invalid-tooltip {
        position: absolute;
        bottom: 100%;
        display: block;
        background-color: black;
        border-radius: 4px;
        padding: 8px;
        font-size: 0.825em;
        color: white;
        opacity: 0;
        pointer-events: none;
        margin-bottom: 10px;
        width: 100%;
        max-width: 320px;
        left: 50%;
        transform: translate(-50%, 10px);
        box-shadow:
            0 2px 4px rgba(0, 0, 0, 0.15),
            0 4px 8px rgba(0, 0, 0, 0.15);
        transition:
            opacity 150ms,
            transform 100ms;

        &:after {
            content: '';
            display: block;
            position: absolute;
            top: 100%;
            left: 50%;
            margin-left: -5px;
            border-style: solid;
            border-width: 5px 5px 0;
            border-color: black transparent transparent;
        }
    }
}
input[type='file'] {
    display: none;
}
