// headlines
h1,
.h1,
h2,
.h2,
h3,
.h3,
h4,
.h4,
h5,
.h5 {
    color: $headline-color;
    font-family: $headline-font;
    display: block;
    line-height: 1.3;
}
h1,
.h1 {
    font-size: 1.6em;
    font-weight: 300;
    margin-bottom: 1em;
    hyphens: manual;
    @media (min-width: $sm) {
        font-size: 1.7777em;
    }
    @media (min-width: $md) {
        font-size: 2.3em;
    }
}
h2,
.h2 {
    font-weight: 700;
    font-size: 1.2em;
    margin-bottom: 1em;
    @media (min-width: $md) {
        font-size: 1.5em;
    }
}
h3,
.h3 {
    font-weight: 700;
    font-size: 1.2em;
    margin-bottom: 1em;
}
h4,
.h4 {
    font-weight: 500;
    margin-bottom: 1em;
}

// paragraphs
p,
li {
    margin-bottom: 1.3em;
    line-height: 1.4em;
}

// links
a {
    text-decoration: none;
    color: $blue-2;
    &:hover {
        text-decoration: underline;
        cursor: pointer;
    }
}
// lists
ol {
    padding-left: 20px;
    > li {
        list-style-position: outside;
    }
}
ul {
    padding-left: 20px;
    > li {
        list-style: outside disc;
    }
    &.check,
    &.-check {
        padding-left: 0;
        > li {
            list-style: inside none;
            margin-bottom: 11px;
            position: relative;
            padding-left: 32px;

            &:before {
                content: '';
                background-image: url('/assets/icons/table_icon_okay.svg');
                background-position: center;
                background-repeat: no-repeat;
                background-size: 80%;
                width: 24px;
                height: 1em;
                display: inline-block;
                position: absolute;
                left: 0;
                top: 2px;
            }

            &.-missing:before {
                background-image: url('/assets/icons/table_icon_nope.svg');
            }
        }
        &.-disabled > li:before {
            filter: grayscale(1) brightness(170%);
        }
    }
    &.-condensed > li {
        margin-bottom: 0.5em;
    }
    &.-disabled > li {
        color: $text-muted;
    }
}

// emphasis
strong,
.strong {
    font-weight: 700;
}
em {
    font-style: italic;
}

// font-weights:
@each $suffix, $font-weight in $font-weights {
    .fw\:#{$suffix} {
        font-weight: $font-weight;
    }
}

// font-sizes:
@each $suffix, $font-size in $font-sizes {
    .fs\:#{$suffix} {
        font-size: $font-size;
    }
}
@each $prefix, $breakpoint in $breakpoints {
    @media (min-width: $breakpoint) {
        @each $suffix, $font-size in $font-sizes {
            .#{$prefix}\:fs\:#{$suffix} {
                font-size: $font-size;
            }
        }
    }
}

small,
.small {
    font-size: 0.8em;
}

// text alignement
.align\: {
    &c,
    &center {
        text-align: center;
    }
    &l,
    &left {
        text-align: left;
    }
    &r,
    &right {
        text-align: right;
    }
    &justify {
        text-align: justify;
    }
}
@each $prefix, $breakpoint in $breakpoints {
    @media (min-width: $breakpoint) {
        .#{$prefix}\:align\: {
            &c,
            &center {
                text-align: center;
            }
            &l,
            &left {
                text-align: left;
            }
            &r,
            &right {
                text-align: right;
            }
            &justify {
                text-align: justify;
            }
        }
    }
}

// misc
sup {
    line-height: 1.6;
    vertical-align: top;
    font-size: 0.75em;
}
.currency-sign {
    font-size: 0.9em;
}
.ellipsify {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    display: block;
    max-width: 100%;
}
.break-word {
    word-wrap: break-word;
}
.pre-wrap {
    white-space: pre-wrap;
}
.nowrap {
    white-space: nowrap;
}
