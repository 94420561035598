@import 'mixins';

// TODO: clean this up plz
body {
    font-size: $font-size-base;
    color: $text-color;
    font-family: $body-font;
    font-weight: normal;
    @media all and (min-width: $md) {
        font-size: $font-size-base-md;
    }
}

// use this to wrap content that should NOT stretch the whole page width:
.content-container,
.medium-container,
.narrow-container {
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    padding-left: $content-padding-xs;
    padding-right: $content-padding-xs;
    @media all and (min-width: $sm) {
        padding-left: $content-padding-sm;
        padding-right: $content-padding-sm;
    }
    @media all and (min-width: $lg) {
        padding-left: $content-padding-lg;
        padding-right: $content-padding-lg;
    }
}
.content-container {
    max-width: $content-width;
}
.medium-container {
    max-width: $medium-content-width;
}
.narrow-container {
    max-width: 450px;
    @media all and (min-width: $md) {
        max-width: $medium-content-width;
    }
    @media all and (min-width: $lg) {
        max-width: $content-width;
    }
}

.bs\:card {
    @include box-shadow-card;
}
