// colors
@each $suffix, $color in $colors {
    // text color
    .c\:#{$suffix} {
        color: $color;
    }
    // background color
    .bg\:#{$suffix} {
        background-color: $color;
    }
    // border color
    .bc\:#{$suffix} {
        border-color: $color;
        @each $direction-suffix, $direction in $directions {
            &\:#{$direction-suffix} {
                border-#{$direction}-color: $color;
            }
        }
        &\:v {
            border-top-color: $color;
            border-bottom-color: $color;
        }
        &\:h {
            border-left-color: $color;
            border-right-color: $color;
        }
    }
}

.bg\:blue-gradient {
    background-image: $gradient-slanted;
}
.bg\:blue-gradient-rev {
    background-image: $gradient-slanted-rev;
}