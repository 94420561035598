.relative {
    position: relative !important;
}
.static {
    position: static !important;
}
.absolute {
    position: absolute !important;
}
.fixed {
    position: fixed !important;
}
.sticky {
    position: sticky !important;
}
@each $prefix, $breakpoint in $breakpoints {
    @media (min-width: $breakpoint) {
        .#{$prefix}\:relative {
            position: relative !important;
        }
        .#{$prefix}\:static {
            position: static !important;
        }
        .#{$prefix}\:absolute {
            position: absolute !important;
        }
        .#{$prefix}\:fixed {
            position: fixed !important;
        }
        .#{$prefix}\:sticky {
            position: sticky !important;
        }
    }
}
