.m-auto {
    margin-left: auto;
    margin-right: auto;
}
@each $margin in $margins {
    .m-#{$margin} {
        margin: #{$margin + $margin-unit};
        @each $suffix, $direction in $directions {
            &\:#{$suffix} {
                margin-#{$direction}: #{$margin + $margin-unit};
            }
        }
        &\:v {
            margin-top: #{$margin + $margin-unit};
            margin-bottom: #{$margin + $margin-unit};
        }
        &\:h {
            margin-left: #{$margin + $margin-unit};
            margin-right: #{$margin + $margin-unit};
        }
    }
}
@each $prefix, $breakpoint in $breakpoints {
    @media (min-width: $breakpoint) {
        .#{$prefix}\:m-auto {
            margin-left: auto;
            margin-right: auto;
        }
        @each $margin in $margins {
            .#{$prefix}\:m-#{$margin} {
                margin: #{$margin + $margin-unit};
                @each $suffix, $direction in $directions {
                    &\:#{$suffix} {
                        margin-#{$direction}: #{$margin + $margin-unit};
                    }
                }
                &\:v {
                    margin-top: #{$margin + $margin-unit};
                    margin-bottom: #{$margin + $margin-unit};
                }
                &\:h {
                    margin-left: #{$margin + $margin-unit};
                    margin-right: #{$margin + $margin-unit};
                }
            }
        }
    }
}
