@import 'variables';

@mixin box-shadow-card {
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.15);
}

@mixin portal-card {
    @include box-shadow-card;
    padding: 0.75rem;
    border-radius: 4px;
    display: block;
    box-sizing: border-box;
    background: $white;
    border: 1px solid $grey-3;
    @media (min-width: $md) {
        padding: 1rem;
    }
}

@mixin visibly-hidden {
    position: absolute;
    left: -10000px;
    top: auto;
    width: 1px;
    height: 1px;
    overflow: hidden;
}
