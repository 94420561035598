@font-face {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 300;
    src: local(''), url('/assets/fonts/roboto-v27-latin-300.woff2') format('woff2'),
        url('/assets/fonts/roboto-v27-latin-300.woff') format('woff');
}
@font-face {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    src: local(''), url('/assets/fonts/roboto-v27-latin-regular.woff2') format('woff2'),
        url('/assets/fonts/roboto-v27-latin-regular.woff') format('woff');
}
@font-face {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 500;
    src: local(''), url('/assets/fonts/roboto-v27-latin-500.woff2') format('woff2'),
        url('/assets/fonts/roboto-v27-latin-500.woff') format('woff');
}
@font-face {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 700;
    src: local(''), url('/assets/fonts/roboto-v27-latin-700.woff2') format('woff2'),
        url('/assets/fonts/roboto-v27-latin-700.woff') format('woff');
}
