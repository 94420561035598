hr {
    border: 0;
    height: 0;
    border-top: 1px solid $grey-3;
    margin: 0.825em 0;
}

.hero-text-box-content {
    h1,
    h2,
    h3,
    h4,
    h5,
    p,
    small,
    em,
    span {
        color: inherit;
    }
}
