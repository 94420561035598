ul.numbered-list,
ol.numbered-list {
    > li {
        &::before {
            line-height: 32px;
            font-size: 32px;
            text-align: center;
            font-weight: 700;
            color: $grey-3;
            padding-right: 12px;
            display: inline-block;
            transform: translateY(4px);
        }
    }
}

ol.numbered-list {
    > li {
        counter-increment: list;

        &::marker {
            content: '';
        }

        &::before {
            content: counter(list);
        }
    }
}

ul.numbered-list {
    > li {
        list-style: none;

        &::before {
            content: attr(data-number);
        }
    }
}